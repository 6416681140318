import { createContext, useState, useContext } from "react";

const UserListContext = createContext(null);

export const UserListProvider = ({ children }) => {
    const [userList, setUserList] = useState(null);

    return <UserListContext.Provider value={{ userList, setUserList }}>{children}</UserListContext.Provider>;
};

export const useUserListContext = () => {
    const ctx = useContext(UserListContext);
    if (!ctx) {
        throw new Error("Not wrapperd");
    }
    return ctx;
};

import {
    Button,
    FormControl,
    FormControlLabel,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useFormik } from "formik";
import { useState } from "react";
import { supabase } from "../../supabaseClient";
import { useUserListContext } from "../../context/UserListContext";

export const AddTaskForm = () => {
    const [isSending, setIsSending] = useState(false);

    const { userList } = useUserListContext();

    const formik = useFormik({
        initialValues: {
            project: "",
            task: "",
            assignee: "",
            deadline: new Date(),
            tcIndex: 0,
            confirmed: false,
            completed: false,
        },
        onSubmit: async (values, actions) => {
            try {
                setIsSending(true);

                const { error } = await supabase.from(`tasks`).insert(values, { returning: "minimal" });
                if (error) {
                    throw error;
                }
                const prevValue = values.project;
                actions.resetForm();
                formik.setFieldValue("project", prevValue);
            } catch (error) {
                alert(error.message);
            } finally {
                setIsSending(false);
            }
        },
    });

    return (
        <>
            <div className="main-container">
                <h2>Dodaj nowe</h2>
                <form onSubmit={formik.handleSubmit}>
                    <Stack direction="row" spacing={3}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="project">Projekt</InputLabel>
                            <Input
                                autoFocus={true}
                                id="project"
                                onChange={formik.handleChange}
                                value={formik.values.project}
                            />
                        </FormControl>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="task">Zadanie</InputLabel>
                            <Input id="task" onChange={formik.handleChange} value={formik.values.task} />
                        </FormControl>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="assignee">Wykonawca</InputLabel>
                            {userList ? (
                                <Select
                                    sx={{ minWidth: 200 }}
                                    id="assignee"
                                    name="assignee"
                                    onChange={formik.handleChange}
                                    value={formik.values.assignee}
                                    defaultValue=""
                                >
                                    {userList.map((element) => (
                                        <MenuItem key={element} value={element}>
                                            {element}
                                        </MenuItem>
                                    ))}
                                </Select>
                            ) : (
                                <p>Wczytuję...</p>
                            )}
                        </FormControl>
                        <FormControl variant="standard">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Deadline"
                                    name="deadline"
                                    inputFormat="dd/MM/yyyy"
                                    value={formik.values.deadline}
                                    onChange={(data) => {
                                        formik.setFieldValue("deadline", data);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="tcIndex">Indeks czasochłonności</InputLabel>
                            <Input id="tcIndex" onChange={formik.handleChange} value={formik.values.tcIndex} />
                        </FormControl>
                        <FormControl variant="standard">
                            <FormControlLabel
                                name="confirmed"
                                value="confirmed"
                                control={<Switch onChange={formik.handleChange} value={formik.values.confirmed} />}
                                label="Zatwierdzone?"
                                labelPlacement="top"
                            />
                        </FormControl>
                        <Button variant="contained" type="submit" disabled={isSending}>
                            Dodaj
                        </Button>
                    </Stack>
                </form>
            </div>
        </>
    );
};

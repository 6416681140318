import { Button } from "@mui/material";
import { TaskTable } from "../../components/Task/TaskTable";
import { useUserContext } from "../../context/UserContext";
import { supabase } from "../../supabaseClient";

export const UserView = () => {
    const { user } = useUserContext();

    return (
        <>
            <div>
                <Button type="text" onClick={() => supabase.auth.signOut()}>
                    Wyloguj
                </Button>
                <h1>Cześć {user.username}!</h1>
            </div>
            <TaskTable username={user.username} admin={user.admin} />
        </>
    );
};

import React from "react";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <h1>Oops... Gruby error</h1>
                    <p>
                        Coś się mocno skopało, ale nie pękaj i{" "}
                        <a style={{ color: "#8e8e8e", cursor: "pointer" }} onClick={() => window.location.reload()}>
                            odśwież
                        </a>
                        .
                    </p>
                </>
            );
        }

        return this.props.children;
    }
}

import { FormControl, Input, InputLabel } from "@mui/material";

export const FormInputWithLabel = ({ name, label, formik }) => {
    return (
        <FormControl variant="standard">
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <Input id={name} onChange={formik.handleChange} value={formik.values[name]} />
        </FormControl>
    );
};

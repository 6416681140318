import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    FormControlLabel,
    Switch,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useUserListContext } from "../../context/UserListContext";
import { supabase } from "../../supabaseClient";
import { ProjectEdit } from "./ProjectEdit";
import { RowList } from "./RowList";

export const TaskTable = ({ username, admin }) => {
    const [data, setData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [columns, setColumns] = useState([]);
    const [filteredBy, setFilteredBy] = useState("");
    const { userList } = useUserListContext();
    const [projectEditing, setProjectEditing] = useState(false);
    const [activeFilter, setActiveFilter] = useState(null);
    const [projectsList, setProjectsList] = useState(null);
    const [refreshState, setRefreshState] = useState(false);
    const [showReady, setshowReady] = useState(false);

    const fetchData = useCallback(async () => {
        if (admin) {
            const { data } = await supabase.from(`tasks`).select(`*`).order(`deadline`, { ascending: true });
            setData(data);
            setColumns([`Projekt`, `Zadanie`, `Deadline`, `Kto?`, `Index czasu`, `zatwierdzony`, `Akcje`]);
            const list = data.map((project) => project.project);
            const uniques = [...new Set(list)];
            setProjectsList(uniques);
            setFilteredData(data);
        } else {
            const { data } = await supabase
                .from(`tasks`)
                .select(`id, project, task, deadline, completed`)
                .eq(`assignee`, username)
                .order(`deadline`, { ascending: true });
            setData(data);
            setColumns([`Projekt`, `Zadanie`, `Deadline`, `Akcje`]);
            const list = data.map((project) => project.project);
            const uniques = [...new Set(list)];
            setProjectsList(uniques);
            setFilteredData(data);
        }
    }, [username, admin]);

    useEffect(() => {
        fetchData().catch(console.error);
    }, [fetchData, refreshState]);

    const filterReady = () => {
        if (!showReady) {
            setFilteredData(data.filter((row) => !row.completed));
        } else {
            setFilteredData(data);
        }
    };

    return (
        <div className="main-container">
            <h2>Lista zadań</h2>
            <div className="filter">
                <h3>Filtruj:</h3>

                {/* Moduł filtrowania */}
                {admin && (
                    <FormControl variant="standard">
                        <InputLabel htmlFor="assignee">Wykonawca</InputLabel>
                        {userList ? (
                            <Select
                                sx={{ minWidth: 200 }}
                                id="assignee"
                                name="assignee"
                                onChange={(e) => {
                                    setFilteredBy(e.target.value);
                                    setActiveFilter("wykonawca");
                                }}
                                value={activeFilter === "projekt" ? "" : filteredBy}
                                defaultValue=""
                            >
                                {userList.map((element) => (
                                    <MenuItem key={element} value={element}>
                                        {element}
                                    </MenuItem>
                                ))}
                                <MenuItem key={`none`} value={``}>
                                    <em>Wszyscy</em>
                                </MenuItem>
                            </Select>
                        ) : (
                            <p>Wczytuję...</p>
                        )}
                    </FormControl>
                )}
                <FormControl variant="standard">
                    <InputLabel htmlFor="project">Projekt</InputLabel>
                    {projectsList ? (
                        <Select
                            sx={{ minWidth: 200 }}
                            id="project"
                            name="project"
                            onChange={(e) => {
                                setFilteredBy(e.target.value);
                                setActiveFilter("projekt");
                            }}
                            value={activeFilter === "wykonawca" ? "" : filteredBy}
                            defaultValue=""
                        >
                            {projectsList.map((element) => (
                                <MenuItem key={element} value={element}>
                                    {element}
                                </MenuItem>
                            ))}
                            <MenuItem key={`none`} value={``}>
                                <em>Wszystkie</em>
                            </MenuItem>
                        </Select>
                    ) : (
                        <p>Wczytuję...</p>
                    )}
                </FormControl>
                <FormControl variant="standard">
                    <FormControlLabel
                        name="showReady"
                        value="showReady"
                        control={
                            <Switch
                                onChange={() => {
                                    setshowReady(!showReady);
                                    filterReady();
                                }}
                            />
                        }
                        label="Ukryj zakończone"
                        labelPlacement="top"
                    />
                </FormControl>
            </div>

            {/* Moduł edycji całego projeku */}
            {admin && (
                <>
                    <Button onClick={() => setProjectEditing(!projectEditing)}>
                        {projectEditing ? "Zwiń" : "Edytuj projekt"}
                    </Button>
                    {projectEditing && (
                        <ProjectEdit
                            projectsList={projectsList}
                            setProjectEditing={setProjectEditing}
                            refresh={refreshState}
                            setRefresh={setRefreshState}
                        />
                    )}
                </>
            )}

            {/* Tabela tasków */}
            <Table sx={{ minWidth: `80vw` }}>
                <TableHead>
                    <TableRow key={"head"}>
                        {columns.map((label) => {
                            return (
                                <TableCell key={label} align="center">
                                    {label}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredData ? (
                        filteredData.map((row) => {
                            return (
                                <RowList
                                    key={row.id}
                                    row={row}
                                    admin={admin}
                                    filter={filteredBy}
                                    refresh={refreshState}
                                    setRefresh={setRefreshState}
                                />
                            );
                        })
                    ) : (
                        <TableRow key={`loading`}>
                            <TableCell key={`loading cell`} align="center" colSpan={6}>
                                Wczytuję...
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
};

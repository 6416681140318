import { Button, Stack } from "@mui/material";
import React from "react";
import { AddTaskForm } from "../../components/Task/AddTaskForm";
import { TaskTable } from "../../components/Task/TaskTable";
import { TcTable } from "../../components/Task/TcTable";
import { useUserContext } from "../../context/UserContext";
import { supabase } from "../../supabaseClient";

export const PlaningView = () => {
    const { user } = useUserContext();

    return (
        <Stack spacing={15}>
            <div>
                <Button type="button" onClick={() => supabase.auth.signOut()}>
                    Wyloguj
                </Button>
                <h1>Oficer {user.username} na pokładzie. Baaaaaczność!</h1>
            </div>
            <AddTaskForm />
            <TcTable />
            <TaskTable user={user.username} admin={user.admin} />
        </Stack>
    );
};

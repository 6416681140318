import { useFormik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TableRow,
    Button,
    TextField,
    TableCell,
    Stack,
    Divider,
} from "@mui/material";
import { FormInputWithLabel } from "./FormInputWithLabel";
import { useUserListContext } from "../../context/UserListContext";
import { supabase } from "../../supabaseClient";

export const TaskRowEditing = ({ row, admin, setEditing, refresh, setRefresh }) => {
    const { userList } = useUserListContext();

    const formik = useFormik({
        initialValues: {
            id: row.id,
            project: row.project,
            task: row.task,
            assignee: row.assignee,
            deadline: row.date,
            tcIndex: row.tcIndex,
            confirmed: row.confirmed,
            completed: row.completed,
        },
        onSubmit: async (values) => {
            try {
                const { error } = await supabase
                    .from(`tasks`)
                    .upsert(values, { returning: "minimal" })
                    .eq("id", formik.values.id);
                if (error) {
                    throw error;
                }
            } catch (error) {
                alert(error.message);
            }
        },
    });

    const markNotReady = async (id) => {
        const { error } = await supabase.from(`tasks`).update({ completed: false }).eq(`id`, id);
        if (error) throw error;
        setRefresh((prev) => !prev);
    };

    const markAsReady = async (id) => {
        const { error } = await supabase.from(`tasks`).update({ completed: true }).eq(`id`, id);
        if (error) throw error;
        setRefresh((prev) => !prev);
    };

    const deleteTask = async (id) => {
        const { error } = await supabase.from(`tasks`).delete().eq(`id`, id);
        if (error) throw error;
        setRefresh((prev) => !prev);
    };

    return (
        <TableRow>
            <TableCell colSpan={7}>
                <form onSubmit={formik.handleSubmit} style={{ display: "flex", justifyContent: "space-around" }}>
                    <div>
                        <FormInputWithLabel name={"project"} label={"Projekt"} formik={formik} />
                    </div>
                    <div>
                        <FormInputWithLabel name={"task"} label={"Zadanie"} formik={formik} />
                    </div>
                    <div>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="assignee">Wykonawca</InputLabel>
                            {userList ? (
                                <Select
                                    sx={{ minWidth: 200 }}
                                    id="assignee"
                                    name="assignee"
                                    onChange={formik.handleChange}
                                    value={formik.values.assignee}
                                >
                                    {userList.map((element) => (
                                        <MenuItem key={element} value={element}>
                                            {element}
                                        </MenuItem>
                                    ))}
                                </Select>
                            ) : (
                                <p>Wczytuję...</p>
                            )}
                        </FormControl>
                    </div>
                    <div>
                        <FormControl variant="standard">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Deadline"
                                    name="deadline"
                                    inputFormat="dd/MM/yyyy"
                                    value={formik.values.deadline}
                                    onChange={(data) => {
                                        formik.setFieldValue("deadline", data);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </div>
                    <div>
                        <FormInputWithLabel name={"tcIndex"} label={"Indeks czasochłonności"} formik={formik} />
                    </div>
                    <div>
                        <Stack direction={row} divider={<Divider orientation="vertical" />}>
                            {row.completed && (
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        markNotReady(row.id);
                                        setEditing(false);
                                    }}
                                >
                                    Nie Gotowe
                                </Button>
                            )}

                            <Button
                                variant="contained"
                                type="submit"
                                onClick={() => {
                                    formik.handleSubmit();
                                    setRefresh((prev) => !prev);
                                    setEditing(false);
                                }}
                            >
                                Zapisz
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                                color="error"
                                onClick={() => {
                                    deleteTask(row.id);
                                    setRefresh((prev) => !prev);
                                    setEditing(false);
                                }}
                            >
                                Usuń
                            </Button>
                        </Stack>
                    </div>
                </form>
            </TableCell>
        </TableRow>
    );
};

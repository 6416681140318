import Auth from "./components/User/Auth";
import { Account } from "./components/User/Account";
import { useEffect, useState } from "react";
import { supabase } from "./supabaseClient";
import { UserProvider } from "./context/UserContext";
import { UserListProvider } from "./context/UserListContext";
import { createTheme, ThemeProvider } from "@mui/material";
import { ErrorBoundary } from "./components/ErrorBoundary";

function App() {
    const [session, setSession] = useState(null);

    useEffect(() => {
        setSession(supabase.auth.session());

        supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });
    }, []);

    const theme = createTheme({
        palette: {
            primary: {
                light: "#8e8e8e",
                main: "#616161",
                dark: "#373737",
                contrastText: "#fff",
            },
        },
    });

    return (
        <UserListProvider>
            <UserProvider>
                <ThemeProvider theme={theme}>
                    <ErrorBoundary>
                        {!session ? <Auth /> : <Account key={session.user.id} session={session} />}
                    </ErrorBoundary>
                </ThemeProvider>
            </UserProvider>
        </UserListProvider>
    );
}

export default App;

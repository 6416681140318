import { styled, TableRow } from "@mui/material";
import { useState } from "react";
import { TaskRowEditing } from "./TaskRowEditing";
import { TaskRow } from "./TaskRow";

export const RowList = ({ row, admin, filter, refresh, setRefresh }) => {
    const [editing, setEditing] = useState(false);

    const ReadyTaskRow = () => {
        const ReadyRow = styled(TableRow)(() => ({ backgroundColor: "lightGray" }));
        return (
            <ReadyRow>
                <TaskRow
                    row={row}
                    admin={admin}
                    editing={editing}
                    setEditing={setEditing}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />
            </ReadyRow>
        );
    };
    const TableTaskRow = () => {
        return (
            <TableRow>
                <TaskRow
                    row={row}
                    admin={admin}
                    editing={editing}
                    setEditing={setEditing}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />
            </TableRow>
        );
    };

    return (
        <>
            {filter ? (
                <>
                    {filter === row.project || filter === row.assignee ? (
                        row.completed ? (
                            <>
                                {editing ? (
                                    <TaskRowEditing
                                        row={row}
                                        admin={admin}
                                        setEditing={setEditing}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                        key={row.id}
                                    />
                                ) : (
                                    <ReadyTaskRow key={row.id} />
                                )}
                            </>
                        ) : (
                            <>
                                {editing ? (
                                    <TaskRowEditing
                                        row={row}
                                        admin={admin}
                                        setEditing={setEditing}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                        key={row.id}
                                    />
                                ) : (
                                    <TableTaskRow key={row.id} />
                                )}
                            </>
                        )
                    ) : null}
                </>
            ) : (
                <>
                    {row.completed ? (
                        <>
                            {editing ? (
                                <TaskRowEditing
                                    row={row}
                                    admin={admin}
                                    setEditing={setEditing}
                                    refresh={refresh}
                                    setRefresh={setRefresh}
                                />
                            ) : (
                                <ReadyTaskRow key={row.id} />
                            )}
                        </>
                    ) : (
                        <>
                            {editing ? (
                                <TaskRowEditing
                                    row={row}
                                    admin={admin}
                                    setEditing={setEditing}
                                    refresh={refresh}
                                    setRefresh={setRefresh}
                                />
                            ) : (
                                <TableTaskRow key={row.id} />
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

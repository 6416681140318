import { createContext, useState, useContext } from "react";

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
    const ctx = useContext(UserContext);
    if (!ctx) {
        throw new Error("Not wrapperd");
    }
    return ctx;
};

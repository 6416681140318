import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    Button,
    Input,
    Stack,
} from "@mui/material";
import { useFormik } from "formik";
import { supabase } from "../../supabaseClient";

export const ProjectEdit = ({ projectsList, setProjectEditing, refresh, setRefresh }) => {
    const formik = useFormik({
        initialValues: {
            project: "",
            confirmed: false,
            deadlineChange: 0,
        },
        onSubmit: async (values) => {
            const { data } = await supabase.from(`tasks`).select(`*`).eq(`project`, formik.values.project);
            data.map((task) => {
                task.confirmed = values.confirmed;
                let newDate = new Date(task.deadline);
                newDate.setDate(newDate.getDate() + values.deadlineChange);
                const day = newDate.getDate();
                const month = newDate.getMonth();
                task.deadline = `${newDate.getFullYear()}-${month + 1}-${day}`;
                return task;
            });
            const { error } = await supabase.from(`tasks`).upsert(data);
            if (error) throw error;
            setRefresh((prev) => !prev);
            setProjectEditing(false);
        },
    });
    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <Stack direction="row" spacing={3}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="project">Projekt do edycji:</InputLabel>
                        {projectsList ? (
                            <Select
                                sx={{ minWidth: 200 }}
                                id="project"
                                name="project"
                                onChange={formik.handleChange}
                                defaultValue=""
                            >
                                {projectsList.map((element) => (
                                    <MenuItem key={element} value={element}>
                                        {element}
                                    </MenuItem>
                                ))}
                                <MenuItem key={`none`} value={``}>
                                    <em>Wszystkie</em>
                                </MenuItem>
                            </Select>
                        ) : (
                            <p>Wczytuję...</p>
                        )}
                    </FormControl>
                    <FormControl variant="standard">
                        <FormControlLabel
                            name="confirmed"
                            value="confirmed"
                            control={<Switch onChange={formik.handleChange} value={formik.values.confirmed} />}
                            label="Zatwierdzony?"
                            labelPlacement="top"
                        />
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="deadlineChange">Przesuń deadline (dni)</InputLabel>
                        <Input
                            id="deadlineChange"
                            onChange={formik.handleChange}
                            defaultValue={formik.values.deadlineChange}
                            type="number"
                        />
                    </FormControl>
                    <Button variant="contained" type="submit">
                        Zatwierdź
                    </Button>
                </Stack>
            </form>
        </div>
    );
};

import { Button, FormControl, Input, InputLabel, Stack } from "@mui/material";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useUserContext } from "../../context/UserContext";
import { useUserListContext } from "../../context/UserListContext";
import { PlaningView } from "../../pages/PlaningView/PlaningView";
import { UserView } from "../../pages/UserView/UserViev";
import { supabase } from "../../supabaseClient";

export const Account = ({ session }) => {
    const [loading, setLoading] = useState(true);
    const [username, setUsername] = useState(null);
    const [authUser, setAuthUser] = useState(supabase.auth.user());
    const { user, setUser } = useUserContext();
    const { setUserList } = useUserListContext();

    useEffect(() => {
        getUserList();
        getProfile();
    }, [session, username]);

    const getUserList = async () => {
        try {
            const { data, error } = await supabase.from(`profiles`).select(`*`);

            if (error) {
                throw error;
            }
            if (data) {
                setUserList(data.map((user) => user.username));
            }
        } catch (error) {
            alert(error.message);
        }
    };

    const getProfile = async () => {
        try {
            setLoading(true);

            let { data, error, status } = await supabase.from(`profiles`).select(`*`).eq("id", authUser.id).single();

            if (error && status !== 406) {
                throw error;
            }

            if (data) {
                setUser(data);
                setUsername(data.username);
            }
        } catch (error) {
            alert(error.message);
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            id: session.user.id,
            username: "",
            email: authUser.email,
            updated_at: new Date(),
        },
        onSubmit: async (values) => {
            setAuthUser(supabase.auth.user());
            setUsername(values.username);

            try {
                setLoading(true);

                let { error } = await supabase.from("profiles").upsert(values, {
                    returning: "minimal", // Don't return the value after inserting
                });

                if (error) {
                    throw error;
                }
            } catch (error) {
                alert(error.message);
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <>
            {username ? (
                user.admin ? (
                    <PlaningView />
                ) : (
                    <UserView />
                )
            ) : (
                <>
                    {loading ? (
                        <div>Zapisuję...</div>
                    ) : (
                        <form onSubmit={formik.handleSubmit}>
                            <Stack spacing={3}>
                                <h1>Witaj po raz pierwszy w naszej appce!</h1>
                                <h2>Uzupełnij swoje dane:</h2>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="username">Imię</InputLabel>
                                    <Input autoFocus={true} id="username" onChange={formik.handleChange} />
                                </FormControl>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="email">E-mail</InputLabel>
                                    <Input
                                        autoFocus={true}
                                        id="email"
                                        onChange={formik.handleChange}
                                        defaultValue={authUser.email}
                                    />
                                </FormControl>

                                <Button variant="contained" type="submit" disabled={loading}>
                                    Zaktualizuj profil
                                </Button>
                            </Stack>
                        </form>
                    )}
                    <Button type="button" onClick={() => supabase.auth.signOut()}>
                        Wyloguj
                    </Button>
                </>
            )}
        </>
    );
};

import { Button, TableCell } from "@mui/material";
import { supabase } from "../../supabaseClient";

export const TaskRow = ({ row, admin, editing, setEditing, refresh, setRefresh }) => {
    const markAsReady = async (id) => {
        const { error } = await supabase.from(`tasks`).update({ completed: true }).eq(`id`, id);
        if (error) throw error;
        setRefresh((prev) => !prev);
    };

    return (
        <>
            <TableCell align="center">{row.project}</TableCell>
            <TableCell align="center">{row.task}</TableCell>
            <TableCell align="center">{row.deadline}</TableCell>
            {admin && (
                <>
                    <TableCell align="center">{row.assignee}</TableCell>
                    <TableCell align="center">{row.tcIndex}</TableCell>
                    <TableCell align="center">{row.confirmed ? `tak` : `nie`}</TableCell>
                </>
            )}
            <TableCell align="center">
                {!row.completed && (
                    <Button variant="contained" onClick={() => markAsReady(row.id)}>
                        Gotowe
                    </Button>
                )}
                {admin && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setEditing(!editing);
                            setRefresh((prev) => !prev);
                        }}
                    >
                        Edytuj
                    </Button>
                )}
            </TableCell>
        </>
    );
};

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { weekNumber } from "weeknumber";
import { useUserListContext } from "../../context/UserListContext";
import { supabase } from "../../supabaseClient";

export const TcTable = () => {
    const [data, setData] = useState(null);
    const [filteredBy, setFilteredBy] = useState("");
    const [tcSumByWeek, setTcSumByWeek] = useState([]);
    const { userList } = useUserListContext();
    const [hide, setHide] = useState(false);

    const fetchData = useCallback(async () => {
        if (filteredBy) {
            const { data } = await supabase.from(`tasks`).select(`deadline, tcIndex`).eq(`assignee`, filteredBy);
            setData(data);
        } else {
            const { data } = await supabase.from(`tasks`).select(`deadline, tcIndex, assignee`);
            setData(data);
        }
    }, [filteredBy]);

    useEffect(() => {
        fetchData().catch(console.error);
    }, [fetchData]);

    const getTcWeekArr = () => {
        if (data) {
            const arr = [];
            data.forEach((elem) => {
                const elemObj = { week: weekNumber(new Date(elem.deadline)), tcIndex: elem.tcIndex };

                if (arr.some((obj) => obj.week === elemObj.week)) {
                    const foundIndex = arr.findIndex((obj) => obj.week === elemObj.week);
                    const sumTc = arr[foundIndex].tcIndex + elemObj.tcIndex;
                    const newObj = { week: arr[foundIndex].week, tcIndex: sumTc };
                    arr[foundIndex] = newObj;
                } else {
                    arr.push(elemObj);
                }
            });
            setTcSumByWeek(arr.sort((a, b) => a.week - b.week));
        }
    };

    useEffect(() => {
        getTcWeekArr();
    }, [data]);

    return (
        <div className="tctable_container main-container">
            <h2>
                Index czasochłonności {filteredBy ? `dla: ${filteredBy}` : null}
                <Button
                    onClick={() => {
                        setHide(!hide);
                    }}
                >
                    {hide ? "Rozwiń" : "Zwiń"}
                </Button>
            </h2>
            {!hide && (
                <>
                    <div className="filter">
                        <h3>Filtruj:</h3>
                        {userList ? (
                            <>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="assignee">Wykonawca</InputLabel>
                                    <Select
                                        name="assignee"
                                        id="assignee"
                                        sx={{ minWidth: 150 }}
                                        defaultValue=""
                                        onChange={(e) => setFilteredBy(e.target.value)}
                                    >
                                        {userList.map((assignee) => (
                                            <MenuItem key={assignee} value={assignee}>
                                                {assignee}
                                            </MenuItem>
                                        ))}
                                        <MenuItem key={`none`} value={``}>
                                            <em>Brak</em>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </>
                        ) : (
                            <p>Loading...</p>
                        )}
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow key={`head row`}>
                                <TableCell key={`week head`} align="center" style={{ fontWeight: "bold" }}>
                                    Tydzień
                                </TableCell>
                                <TableCell key={`tc head`} align="center">
                                    TC Index
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tcSumByWeek.map((elem, i) => {
                                return (
                                    <TableRow key={`Data row ${i}`}>
                                        <TableCell key={`${elem.week}${i}`} align="center">
                                            {elem.week}
                                        </TableCell>
                                        <TableCell key={`${elem.tcIndex}${i}`} align="center">
                                            {elem.tcIndex}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </>
            )}
        </div>
    );
};

import { Button, FormControl, Input, InputLabel, Stack } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { supabase } from "../../supabaseClient";

export default function Auth() {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        onSubmit: async ({ email }) => {
            if (email.includes("247studio.co")) {
                try {
                    setLoading(true);

                    const { error } = await supabase.auth.signIn({ email });
                    if (error) throw error;
                    alert("Wysłałem Ci link, sprawdź pocztę.");
                } catch (error) {
                    alert(error.error_description || error.message);
                } finally {
                    setLoading(false);
                }
            } else {
                alert("To nie jest mail zespołu 247s! Zgłaszam włamanie na psiarnię!");
            }
        },
    });

    return (
        <>
            <Stack spacing={3}>
                <h1>Witaj w "247 ToDo App"</h1>
                <h2> Wpisz e-mail aby się zalogować </h2>
                {loading ? (
                    "Przetwarzam..."
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="email">E-mail</InputLabel>
                            <Input
                                autoFocus={true}
                                id="email"
                                onChange={formik.handleChange}
                                placeholder="Podaj maila mordo!"
                            />
                        </FormControl>
                        <Button type="submit" variant="contained">
                            Wyślij link
                        </Button>
                    </form>
                )}
            </Stack>
        </>
    );
}
